import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button', 'content']

  select({ target }) {
    const button = this.buttonTargets.find((element) => element.contains(target))
    const buttonIndex = this.buttonTargets.indexOf(button)
    if (buttonIndex < 0) return;

    this.buttonTargets.forEach((button, index) => {
      const chevron = button.querySelector('.fa-chevron-down')
      if (index === buttonIndex) {
        chevron.classList.toggle('chevron-active')
      } else {
        chevron.classList.toggle('chevron-active', false)
      }
    })

    this.contentTargets.forEach((content, index) => {
      if (index === buttonIndex) {
        content.classList.toggle('dropdown-active')
      } else {
        content.classList.toggle('dropdown-active', false)
      }
    })
  }
}
