import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['dropdown', 'submitButton']

  toggle(event) {
    const checkbox = event.target

    if (checkbox.checked) {
      this.dropdownTarget.hidden = false
    } else {
      this.submitButtonTarget.click()
      this.dropdownTarget.hidden = true
    }
  }
}
