import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button', 'content']

  connect() {
    this.select({ target: this.buttonTargets[0] })
  }

  select({ target }) {
    const button = this.buttonTargets.find((element) => element.contains(target))
    const buttonIndex = this.buttonTargets.indexOf(button)
    if (buttonIndex < 0) return;

    this.buttonTargets.forEach((button, index) => {
      button.classList.toggle('active', index === buttonIndex)
    })

    this.contentTargets.forEach((content, index) => {
      content.classList.toggle('content-active', index === buttonIndex)
    })
  }
}