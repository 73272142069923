import { Controller } from "@hotwired/stimulus";
import { downloadDesignStepFile } from "../helpers/LegacyJquery";
import { graphqlQuery } from "../api/graphql";

export default class extends Controller {
  static values = { designId: Number, buttonId: String };

  async download() {
    const originalHTMLContent = this.element.innerHTML;
    this.element.innerHTML = `
      <div class="design_viewer_generating_step_spinner"></div>
      <div class="design_viewer_generating_step_text">Generating the .STEP file</div>
    `;

    const assemblyGraphJSON = await this.fetchAssemblyGraphJSON(
      this.designIdValue,
    );
    if (assemblyGraphJSON) {
      downloadDesignStepFile(
        assemblyGraphJSON,
        this.buttonIdValue,
        () => (this.element.innerHTML = originalHTMLContent),
      );
    } else {
      this.element.innerHTML = originalHTMLContent;
      toastr.error("The design has no contents to export.");
    }
  }

  async fetchAssemblyGraphJSON(designId) {
    const query = `
    query GetDesign($id: ID!) {
      design(id: $id) {
        item {
          id
          lastGraph {
            graph
            designId
            graphVersion
          }
        }
        errors {
          title
          message
        }
      }
    }
    `;

    try {
      const data = await graphqlQuery(query, { id: designId });
      return data.data.design.item.lastGraph;
    } catch (error) {
      toastr.error("Sorry, there was an unexpected error. Please try again.");
      console.error(error);
    }
  }
}
