import { Controller } from '@hotwired/stimulus'
import { toggleLikes } from '../api/Likes/Service'
import I18n from '../helpers/I18n'

export default class extends Controller {
  static targets = ['count', 'thumbsUp', 'plusIcon']
  static values = {
    liked: Boolean,
    props: Object,
    userLogged: Boolean,
    likeCount: Number,
  }

  toggle() {
    if (!this.userLoggedValue) {
      toastr.error(
        "You must be signed in to like this design <br><a style='text-decoration:underline;' href='/users/sign_in'>Sign in now</a>."
      )
      return
    }

    toggleLikes({
      ...this.propsValue,
      callback: data => this.successCallback(data),
    })

    this.toggleIconAnimations()
  }

  successCallback(data) {
    if (data.res) {
      this.likedValue = true
      this.changeCount(1)
    } else if (this.likedValue) {
      this.changeCount(-1)
    }
  }

  changeCount(amount) {
    this.likeCountValue = Math.max(this.likeCountValue + amount, 0)
    this.countTarget.innerHTML = `${this.likeCountValue} ${I18n.t('views.designs.show.likes')}`
  }

  toggleIconAnimations() {
    if (!this.element.classList.contains('liked')) {
      this.thumbsUpTarget.classList.add('thumbs-up-animation')
      if (this.hasPlusIconTarget) {
        this.plusIconTarget.classList.add('plus-icon-animation')
      }
    } else {
      this.thumbsUpTarget.classList.remove('thumbs-up-animation')
      if (this.hasPlusIconTarget) {
        this.plusIconTarget.classList.remove('plus-icon-animation')
      }
    }

    this.element.classList.toggle('liked')
  }
}
