export const toggleLikes = ({
  parent,
  id,
  callback,
}: {
  parent: 'design' | 'comment'
  id: number
  callback: (data) => void
}): void => {
  $.ajax({
    url: `/${parent}s/${id}/like`,
    headers: { parent },
    data: { payload: 'vention' },
    type: 'POST',
    success: data => callback(data),
  })
}
