import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    url: String,
    windowName: String,
  }
  click() {
    window.open(this.urlValue, this.windowNameValue, 'left=100,top=100,height=500,width=500')
  }
}
