import { Controller } from '@hotwired/stimulus'
import { getNumberOfLinesOfText } from '../helpers/DomHelper'

export default class extends Controller {
  static targets = ['content', 'button']
  static values = { lines: Number, buttonActive: Boolean }

  connect() {
    this.buttonInnerHTML = this.buttonTarget.innerHTML
    this.initializeClamp()

    this.initializeClamp = this.initializeClamp.bind(this)
    window.addEventListener('resize', this.initializeClamp)
  }

  disconnect() {
    window.removeEventListener('resize', this.initializeClamp)
  }

  initializeClamp() {
    this.setContentLineClampNumber()
    this.toggleClampLine(true)
    requestAnimationFrame(() => {
      this.toggleClampLine(this.needsClamping)
      this.toggleButtonVisibility()
      this.buttonActiveValueChanged()
    })
  }

  setContentLineClampNumber() {
    this.contentTargets.forEach(element => (element.style['-webkit-line-clamp'] = this.linesValue))
  }

  toggleClampLine(force) {
    this.contentTargets.forEach(element => element.classList.toggle('line-clamp', force))
  }

  toggleButtonVisibility() {
    this.buttonTarget.classList.toggle('hidden', !this.needsClamping)
  }

  toggle() {
    this.buttonActiveValue = !this.buttonActiveValue
    this.buttonTarget.classList.toggle('description-expanded')
  }

  buttonActiveValueChanged() {
    if (this.isExpanded) {
      this.toggleClampLine(false)
    } else {
      const lineCount = getNumberOfLinesOfText(this.contentTarget)
      this.toggleClampLine(lineCount >= this.linesValue)
    }
    this.setButtonText()
  }

  setButtonText() {
    this.buttonTarget.innerHTML = this.isExpanded
      ? this.buttonTarget.dataset.toggleWith
      : this.buttonInnerHTML || this.buttonTarget.innerHTML
  }

  get isExpanded() {
    return this.buttonActiveValue
  }

  get needsClamping() {
    // check if the content is overflowing
    return this.contentTarget.scrollHeight > this.contentTarget.clientHeight
  }
}
