import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['main', 'item', 'itemWrapper']
  static classes = ['active']
  static values = {
    activeIndex: Number,
    needsGallery: Boolean,
  }

  show(event) {
    this.setActivateIndex(event.currentTarget)
  }

  activeIndexValueChanged() {
    if (this.needsGalleryValue) this.setActiveImage()
  }

  setActivateIndex(element) {
    this.activeIndexValue = [...element.parentNode.children].indexOf(element)
  }

  setActiveImage() {
    this.setMainImage()
    this.setActiveThumbnail()
  }

  setMainImage() {
    this.mainTarget.src = this.activeThumbnail.src
    this.mainTarget.alt = this.activeThumbnail.alt
  }

  setActiveThumbnail() {
    this.itemWrapperTargets.forEach((element, i) =>
      element.classList.toggle(this.activeClass, this.activeIndexValue === i)
    )
  }

  get activeThumbnail() {
    return this.itemTargets[this.activeIndexValue]
  }
}
