// https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform#maps_places_autocomplete_addressform-javascript
import { Controller } from '@hotwired/stimulus'
import {
  COUNTRIES_BY_CODE,
  US_STATES_BY_CODE,
  CA_STATES_BY_CODE
} from '../constants/Geography'

export default class extends Controller {
  static targets = ['address1', 'address2', 'country', 'state', 'postalCode', 'city']
  static values = {
    useNativeInputSetter: Boolean
  }

  connect() {
    if (typeof google != 'undefined') {
      this.initGooglePlacesAutocomplete()
    }
  }

  initGooglePlacesAutocomplete() {
    this.autocomplete = new google.maps.places.Autocomplete(this.address1Target)
    this.autocomplete.setFields(['address_components'])
    this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))
  }

  placeChanged() {
    const place = this.autocomplete.getPlace()

    let address1 = ''
    let country = ''
    let state = ''
    let city = ''
    let postalCode = ''

    for (const component of place.address_components) {
      const componentType = component.types[0]

      switch (componentType) {
        case 'street_number': {
          address1 = `${component.short_name} ${address1}`
          break
        }

        case 'route': {
          address1 += component.long_name
          break
        }

        case 'postal_code': {
          postalCode = component.short_name
          break
        }
        case 'administrative_area_level_1': {
          state = component.short_name
          break
        }
        case 'locality':
        case 'postal_town':
        case 'sublocality_level_1': {
          if (component.short_name) {
            city = component.short_name
            break
          }
        }

        case 'country': {
          country = component.short_name
          break
        }
      }
    }

    // Overwrite search field with just address1
    this.address1Target.value = address1

    // Set country first as the state field depends on it
    if (this.useNativeInputSetterValue) {
      this.nativeInputValueSetter(this.countryTarget, COUNTRIES_BY_CODE[country])
    } else {
      this.countryTarget.value = country
      this.countryTarget.dispatchEvent(new Event('change'))
    }

    // Other fields
    if (this.useNativeInputSetterValue) {
      const stateValue = CA_STATES_BY_CODE[state] || US_STATES_BY_CODE[state] || state
      this.nativeInputValueSetter(this.stateTarget, stateValue)
    } else {
      this.stateTarget.value = state
    }

    this.postalCodeTarget.value = postalCode
    this.cityTarget.value = city

    // After filling the form with address components from the Autocomplete
    // prediction, set cursor focus on the second address line to encourage
    // entry of subpremise information such as apartment, unit, or floor number.
    this.address2Target.focus()
  }

  // For accessibility, prevent form submission when navigating the autocomplete options
  keydown(event) {
    if (event.key == 'Enter') {
      event.preventDefault()
    }
  }

  nativeInputValueSetter(input, value) {
    const inputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      'value').set
    inputValueSetter.call(input, value)
    const stateEvent = new Event('input', { bubbles: true })
    input.dispatchEvent(stateEvent)
    // Runs focus in order to close any react autocomplete menus after changing input value
    input.focus()
  }
}
