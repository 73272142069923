import { Controller } from '@hotwired/stimulus'

/**
 * Sets the state input field according to selected country
 *
 */
export default class extends Controller {
  static values = { statesByCountry: Object }
  static targets = ['stateParent', 'countryInput']

  setState(e) {
    const selectedCountryCode = e.target.value
    this.setStatesFromCountry(selectedCountryCode)
  }

  setStatesFromCountry(countryCode) {
    const states = this.statesByCountryValue[countryCode]

    if (states) this.setStateAsSelectBox(states)
    else this.setStateAsTextInput()
  }

  setStateAsSelectBox(states) {
    const select = document.createElement('select')
    this.cloneAttributes(select, this.stateInput)
    this.appendStateOptions(select, states)
    this.replaceStateInput(select)
  }

  setStateAsTextInput() {
    const input = document.createElement('input')
    this.cloneAttributes(input, this.stateInput)
    this.replaceStateInput(input)
  }

  replaceStateInput(newElement) {
    this.stateInput.parentNode.replaceChild(newElement, this.stateInput)
  }

  appendStateOptions(element, states) {
    element.appendChild(this.generateOption())

    for (const stateCode in states) {
      if (!Object.hasOwnProperty.call(states, stateCode)) continue

      const option = this.generateOption({ label: states[stateCode], value: stateCode })

      element.appendChild(option)
    }
  }

  generateOption({ label = null, value = null } = {}) {
    const option = document.createElement('option')
    if (value) option.value = value
    if (label) option.innerText = label
    return option
  }

  cloneAttributes(target, source) {
    const except = ['type', 'value']
    const attributes = [...source.attributes]

    attributes.forEach(attr => {
      if (!except.includes(attr.nodeName)) target.setAttribute(attr.nodeName, attr.nodeValue)
    })
  }

  get stateInput() {
    return this.stateParentTarget.querySelector('select, input')
  }
}
