import { Controller } from '@hotwired/stimulus'

/**
 * Toggles targets according to input value
 *
 * targets-value: {
 *  <selector>: { <hide | show>: <value: string | string[]> }
 * }
 *
 * if `show` is defined, the target will be shown only for the values in `show` and will be hidden for all other values
 * if `hide` is defined, the target will be hidden only for the values in `hide` and will be shown for all other values
 */
export default class extends Controller {
  static values = { targets: Object }
  static classes = ['toggle']

  connect() {
    const selectedValue = this.element.value
    this.toggleAccordingToValue(selectedValue)
  }

  toggle(e) {
    const selectedValue = e.target.value
    this.toggleAccordingToValue(selectedValue)
  }

  toggleAccordingToValue(selectedValue) {
    for (const selector in this.targetsValue) {
      const { hide, show } = this.targetsValue[selector]
      const hideValues = [hide].flat()
      const showValues = [show].flat()

      if (show === undefined && hide === undefined) continue
      if (show !== undefined && hide !== undefined) {
        console.error(
          `Definition for selector "${selector}" should contain either "show" or "hide", not both.`
        )
        continue
      }

      let showElement
      if (show) showElement = showValues.includes(selectedValue)
      else showElement = !hideValues.includes(selectedValue)

      document.querySelectorAll(selector).forEach(element => {
        element.classList.toggle(this.toggleClass, !showElement)
      })
    }
  }
}
