// There is also copy_to_clipboard_button_controller.js which is just for a single button

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['elementWithText', 'copyButton', 'copyIconButton']
  disconnect() {
    if (this.timeoutHandle) {
      window.clearTimeout(this.timeoutHandle)
    }
  }

  copy() {
    const copyText = this.elementWithTextTarget.innerText
    const textArea = document.createElement('textarea')
    textArea.textContent = copyText
    document.body.append(textArea)
    textArea.select()
    document.execCommand('copy')
    textArea.remove()

    if (this.timeoutHandle) {
      return
    }

    if (this.hasCopyButtonTarget) {
      const originalHTML = this.copyButtonTarget.innerHTML
      this.copyButtonTarget.innerText = 'Copied!'

      this.timeoutHandle = setTimeout(() => {
        this.copyButtonTarget.innerHTML = originalHTML
        this.timeoutHandle = null
      }, 3000)
    }

    if (this.hasCopyIconButtonTarget) {
      const originalHTML = this.copyIconButtonTarget.innerHTML
      this.copyIconButtonTarget.innerHTML = '<i class="fas fa-check"></i>'

      this.timeoutHandle = setTimeout(() => {
        // sanitize just in case
        this.copyIconButtonTarget.innerHTML = DOMPurify.sanitize(originalHTML)
        this.timeoutHandle = null
      }, 3000)
    }
  }
}
