import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['rowTemplate', 'rows']

  deleteRow(event) {
    const deleteButton = event.target
    deleteButton.parentNode.remove()
  }

  addRow() {
    const clone = this.rowTemplateTarget.content.cloneNode(true)
    this.rowsTarget.appendChild(clone)
  }
}
