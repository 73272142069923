import { Controller } from '@hotwired/stimulus'
import DesignService from '../api/Designs/Service'

export default class extends Controller {
  static values = { designId: Number }

  download() {
    DesignService.downloadBom(this.designIdValue)
  }
}
